import Vue from 'vue'
import VueRouter from 'vue-router'

let Home = () => import('../views/Home/Home.vue')
let LandPage = () => import('../components/LandPage/LandPage.vue')
let About = () => import('../components/About/About.vue')
let Recommend = () => import('../components/About/widgets/Recommend/Recommend.vue')
let TeachMethod = () => import('../components/About/widgets/TeachMethod/TeachMethod.vue')
let TeamIntro = () => import('../components/About/widgets/TeamIntro/TeamIntro.vue')
let Business = () => import('../components/About/widgets/Business/Business.vue')
let privacypolicy = () => import('../components/About/privacypolicy-html-special-cn.vue')
let terms = () => import('../components/About/terms-conditions-html-special-cn.vue')

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: "",
                component: LandPage
            },
            {
                path: "aboutus",
                name: "About",
                component: About,
                children: [
                    {
                        path: "",
                        component: TeamIntro
                    },
                    {
                        path: "/aboutus/recommend",
                        component: Recommend
                    },
                    {
                        path: "/aboutus/method",
                        component: TeachMethod
                    },
                    {
                        path: "/aboutus/team",
                        component: TeamIntro
                    },
                    {
                        path: "/aboutus/contact",
                        component: Business
                    }
                ]
            }
        ]
    },
    {
        path: "/privacypolicy-html-special-cn",
        component: privacypolicy
    },
    {
        path: "/terms-conditions-html-special-cn",
        component: terms
    },
    {
        path: '*',
        redirect: '/'
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router
